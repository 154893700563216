import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clearSuccess } from "../store/actions/actions";

const Success = () => {
	const dispatch = useDispatch();
	const clearSuccessNow = useCallback(() => dispatch(clearSuccess()), [dispatch]);
	useEffect(() => {
		return () => clearSuccessNow();
	}, [clearSuccessNow]);

	const success = useSelector((state) => state.main.success);
	return success && <div className="success">{success}</div>;
};

export default Success;
