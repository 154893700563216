import * as actionTypes from "../actions/actionTypes";

const initialState = {
	loading: false,
	error: null,
	success: null,
	tempSuccess: null,
	tempError: null,
	version: "1.1.0",
	redirect: null,
	uniqueKey: null,
	flat: null,
	registration: null
};

const startLoading = (state, action) => {
	return { ...state, loading: true, error: null };
};

const stopLoading = (state, action) => {
	return { ...state, loading: false };
};

const setError = (state, action) => {
	return { ...state, error: action.error };
};

const clearError = (state, action) => {
	return { ...state, error: null };
};

const setSuccess = (state, action) => {
	return { ...state, success: action.success };
};

const setTempSuccess = (state, action) => {
	return { ...state, tempSuccess: action.success };
};

const setTempError = (state, action) => {
	return { ...state, tempError: action.error };
};

const clearSuccess = (state, action) => {
	return { ...state, success: null };
};

const clearTempSuccess = (state, action) => {
	return { ...state, tempSuccess: null };
};

const clearTempError = (state, action) => {
	return { ...state, tempError: null };
};

const setRedirect = (state, action) => {
	return { ...state, redirect: action.redirect };
};

const clearRedirect = (state, action) => {
	return { ...state, redirect: null };
};

const storeFlat = (state, action) => {
	return { ...state, flat: action.flat };
};

const storeRegistration = (state, action) => {
	return { ...state, registration: action.registration };
};

const clearRegistration = (state, action) => {
	return { ...state, registration: null };
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.START_LOADING:
			return startLoading(state, action);
		case actionTypes.STOP_LOADING:
			return stopLoading(state, action);
		case actionTypes.SET_ERROR:
			return setError(state, action);
		case actionTypes.CLEAR_ERROR:
			return clearError(state, action);
		case actionTypes.SET_SUCCESS:
			return setSuccess(state, action);
		case actionTypes.CLEAR_SUCCESS:
			return clearSuccess(state, action);
		case actionTypes.SET_TEMP_SUCCESS:
			return setTempSuccess(state, action);
		case actionTypes.CLEAR_TEMP_SUCCESS:
			return clearTempSuccess(state, action);
		case actionTypes.SET_TEMP_ERROR:
			return setTempError(state, action);
		case actionTypes.CLEAR_TEMP_ERROR:
			return clearTempError(state, action);
		case actionTypes.SET_REDIRECT:
			return setRedirect(state, action);
		case actionTypes.CLEAR_REDIRECT:
			return clearRedirect(state, action);
		case actionTypes.STORE_FLAT:
			return storeFlat(state, action);
		case actionTypes.STORE_REGISTRATION:
			return storeRegistration(state, action);
		case actionTypes.CLEAR_REGISTRATION:
			return clearRegistration(state, action);

		default:
			return state;
	}
};

export default reducer;
