import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as routes from "../../../shared/routes";
import { _createAccount, _setError } from "../../../store/actions/actions";
import { validateEmail } from "../../../shared/functions";

import Success from "../../../ui/Success";
import Error from "../../../ui/Error";
import Back from "../../../components/Back/Back";

const CreateAccount = () => {
	const [eMail, setEMail] = useState("");
	const [eMailValid, setEMailValid] = useState(false);
	const flat = useSelector((state) => state.main.flat);
	const success = useSelector((state) => state.main.success);
	const error = useSelector((state) => state.main.error);
	const dispatch = useDispatch();
	const createAccount = (flatId, eMail) => dispatch(_createAccount(flatId, eMail));
	const setError = useCallback((error) => dispatch(_setError(error)), [dispatch]);
	const ref = useRef();

	useEffect(() => {
		if (flat) {
			ref.current.focus();
		} else {
			setError("Bitte rufen Sie unsere Website mit Ihrem Smartphone auf, indem Sie den AERZTEFON-QR-Code Ihrer Wohnung fotografieren. Anschliessend können Sie auf dem Smartphone ein AERZTEFON-Konto erstellen.");
		}
	}, [flat, setError]);

	const sendMail = (event) => {
		event.preventDefault();
		createAccount(flat.id, eMail);
	};

	const enterMail = (event) => {
		const eMail = event.target.value;
		setEMail(eMail);
		setEMailValid(validateEmail(eMail));
	};

	return (
		<div className="indent">
			<Back to={routes.LOGIN}>Anmelden</Back>
			<h1>AERZTEFON-Konto erstellen</h1>
			{!flat ? (
				<Error />
			) : (
				<Fragment>
					{success ? (
						<Success />
					) : (
						<div className="panel">
							<h4>
								Damit Sie Ihre persönlichen Daten hinterlegen können, müssen Sie erst ein AERZTEFON-Konto erstellen.
								<br />
								Geben Sie Ihre E-Mail Adresse an, wir werden Ihnen dann eine E-Mail senden mit Hilfe derer Sie Ihr AERZTEFON-Konto erstellen können.
							</h4>
							{error && <Error />}
							<form onSubmit={sendMail}>
								<label>
									<span>E-Mail</span>
									<input ref={ref} type="text" className={eMail.length && !eMailValid ? "error" : ""} autoComplete="username" value={eMail} maxLength={128} onChange={enterMail} />
								</label>
								<div className="buttonRow">
									<button className="right" type="submit" disabled={!eMailValid}>
										Absenden
									</button>
								</div>
							</form>
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CreateAccount;
