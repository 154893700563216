import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as routes from "../../shared/routes";
import { _resetPassword } from "../../store/actions/actions";
import { validateEmail } from "../../shared/functions";

import Success from "../../ui/Success";
import Error from "../../ui/Error";
import Back from "../../components/Back/Back";

const PasswordForgotten = () => {
	const [eMail, setEMail] = useState("");
	const [eMailValid, setEMailValid] = useState(false);
	const success = useSelector((state) => state.main.success);
	const dispatch = useDispatch();
	const resetPassword = (eMail) => dispatch(_resetPassword(eMail));
	const ref = useRef();

	const sendMail = (event) => {
		event.preventDefault();
		resetPassword(eMail);
	};

	const enterMail = (event) => {
		const eMail = event.target.value;
		setEMail(eMail);
		setEMailValid(validateEmail(eMail));
	};

	return (
		<div className="indent">
			<Back to={routes.LOGIN}>Anmelden</Back>
			<h1>Passwort vergessen</h1>
			{success ? (
				<Success />
			) : (
				<div className="panel">
					<h4>Geben Sie Ihre E-Mail Adresse an, mit welcher Sie sich bei uns registriert haben. Wir werden Ihnen dann eine E-Mail senden mit Hilfe derer Sie Ihr Passwort zurücksetzen können.</h4>
					<Error />
					<form onSubmit={sendMail}>
						<label>
							<span>E-Mail</span>
							<input ref={ref} type="text" className={eMail.length && !eMailValid ? "error" : ""} autoComplete="username" value={eMail} maxLength={128} onChange={enterMail} />
						</label>
						<div className="buttonRow">
							<button className="right" type="submit" disabled={!eMailValid}>
								Absenden
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default PasswordForgotten;
