import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Left } from "../../assets/images/angle-left-solid.svg";

const Back = ({ to, children }) => {
	return (
		<div className="back">
			<Link to={to}>
				<Left /> {children}
			</Link>
		</div>
	);
};

export default Back;
