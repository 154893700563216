import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clearTempSuccess } from "../store/actions/actions";

const TempSuccess = () => {
	const success = useSelector((state) => state.main.tempSuccess);
	const dispatch = useDispatch();
	const dispatchClearSuccess = useCallback(() => dispatch(clearTempSuccess()), [dispatch]);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (success) {
			setShowSuccess(true);
			window.setTimeout(() => setShowSuccess(false), 4500);
			window.setTimeout(dispatchClearSuccess, 5000);
		}
	}, [success, dispatchClearSuccess]);

	return (
		<div className={"tempSuccess" + (showSuccess ? " show" : "")}>
			<div>{success}</div>
		</div>
	);
};

export default TempSuccess;
