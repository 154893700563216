import * as actionTypes from "./actionTypes";
import axios from "../../aerzteAxios";

import * as routes from "../../shared/routes";

import { updateTokens, storeUserData, getFlat } from "./userActions";

export const startLoading = () => {
	return {
		type: actionTypes.START_LOADING
	};
};

export const stopLoading = () => {
	return {
		type: actionTypes.STOP_LOADING
	};
};

export const _setError = (error) => {
	window.scrollTo({ top: 0, behavior: "smooth" });
	return {
		type: actionTypes.SET_ERROR,
		error: error
	};
};

export const _clearError = () => {
	return {
		type: actionTypes.CLEAR_ERROR
	};
};

export const clearSuccess = () => {
	return {
		type: actionTypes.CLEAR_SUCCESS
	};
};

export const clearTempSuccess = () => {
	return {
		type: actionTypes.CLEAR_TEMP_SUCCESS
	};
};

export const clearTempError = () => {
	return {
		type: actionTypes.CLEAR_TEMP_ERROR
	};
};

const setSuccess = (success) => {
	return {
		type: actionTypes.SET_SUCCESS,
		success: success
	};
};

export const setTempSuccess = (success) => {
	return {
		type: actionTypes.SET_TEMP_SUCCESS,
		success: success
	};
};

export const setTempError = (error) => {
	return {
		type: actionTypes.SET_TEMP_ERROR,
		error: error
	};
};

export const setRedirect = (redirect) => {
	return {
		type: actionTypes.SET_REDIRECT,
		redirect: redirect
	};
};

export const _clearRedirect = () => {
	return {
		type: actionTypes.CLEAR_REDIRECT
	};
};

export const storeFlat = (flat) => {
	return {
		type: actionTypes.STORE_FLAT,
		flat: flat
	};
};

export const _getFlat = (uniqueKey) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("flat/" + uniqueKey)
			.then((response) => {
				dispatch(storeFlat(response.data));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(setRedirect(routes.HOME));
				dispatch(setTempError("Diese Wohnung existiert nicht."));
				dispatch(stopLoading());
			});
	};
};

export const _tenantCall = (uniqueKey, lat, lng) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			uniqueKey: uniqueKey,
			lat: lat,
			lng: lng
		};
		axios
			.post("tenantCall", data)
			.then((response) => {
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(stopLoading());
			});
	};
};

export const _createAccount = (flatId, eMail) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			flatId: flatId,
			eMail: eMail
		};
		axios
			.post("createAccount", data)
			.then((response) => {
				dispatch(setSuccess("Wir haben Ihnen eine E-Mail an «" + eMail + "» gesendet, mit Hilfe derer Sie Ihr Konto erstellen können."));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(stopLoading());
				if (err.response.status === 406) {
					dispatch(_setError("Wir haben Ihnen bereits eine E-Mail an «" + eMail + "» gesendet, mit Hilfe derer Sie Ihr Konto erstellen können. Ist diese vielleicht im SPAM gelandet?"));
				} else {
					dispatch(_setError("Es ist ein Fehler aufgetreten."));
				}
			});
	};
};

const storeRegistration = (registration) => {
	return {
		type: actionTypes.STORE_REGISTRATION,
		registration: registration
	};
};

export const _checkCreateAccountKey = (uniqueKey) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("checkCreateAccountKey/" + uniqueKey)
			.then((response) => {
				dispatch(storeRegistration(response.data));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Dieser Link ist nicht mehr gültig. Bitte fotografieren Sie Ihren AERZTEFON-QR-Code und starten Sie die Registration erneut."));
				dispatch(stopLoading());
			});
	};
};

const clearRegistration = () => {
	return {
		type: actionTypes.CLEAR_REGISTRATION
	};
};

export const _createPassword = (userId, password) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			password: password
		};
		axios
			.patch("createPassword/" + userId, data)
			.then((response) => {
				dispatch(updateTokens(response.data));
				dispatch(clearRegistration());
				dispatch(storeUserData(response.data));
				dispatch(getFlat(userId, routes.ACCOUNT));
				dispatch(setTempSuccess("Ihr AERZTEFON-Konto wurde erfolgreich erstellt."));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(stopLoading());
			});
	};
};

export const _resetPassword = (eMail) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			eMail: eMail
		};
		axios
			.post("resetPassword", data)
			.then((response) => {
				dispatch(updateTokens(response.data));
				dispatch(setSuccess("Wir haben Ihnen eine E-Mail mit einem Link gesendet, mit welchem Sie Ihr Passwort neu setzen können."));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Es ist ein Fehler aufgetreten. Sind Sie sicher, dass Sie ein AERZTEFON-Konto mit der E-Mail Adresse «" + eMail + "» haben?"));
				dispatch(stopLoading());
			});
	};
};

export const _setNewPassword = (uniqueKey, password) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			password: password
		};
		axios
			.patch("newPassword/" + uniqueKey, data)
			.then((response) => {
				dispatch(setTempSuccess("Ihr neues Passwort wurde erfolgreich gespeichert."));
				dispatch(setRedirect(routes.HOME));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Ihr neues Passwort konnte nicht gespeichert werden. Beachten Sie, dass der Link in der E-Mail nur 5 Stunden gültig ist."));
				dispatch(stopLoading());
			});
	};
};

export const _orderStickers = (data) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.post("orderStickers", data)
			.then((response) => {
				dispatch(setSuccess("Wir werden Ihnen Ihre persönlichen QR-Codes im April 2023 per Post zustellen."));
				dispatch(setRedirect(routes.HOME));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Bei der Bestellung Ihrer persönlichen QR-Codes ist ein Fehler aufgetreten."));
				dispatch(stopLoading());
			});
	};
};
