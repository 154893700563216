import * as actionTypes from "../actions/actionTypes";

const initialState = {
	token: null,
	refreshToken: null,
	systemTimeDifference: 0,
	autoSignupTried: false,
	data: null,
	medicalInsurances: []
};

const authLogout = (state, action) => {
	return { ...initialState, autoSignupTried: true };
};

const updateTokens = (state, action) => {
	const userTime = new Date();
	return { ...state, token: action.token, refreshToken: action.refreshToken, systemTimeDifference: action.serverTime - userTime.getTime(), autoSignupTried: true };
};

const setAutoLoginTried = (state, action) => {
	return { ...state, autoSignupTried: true };
};

const storeUserData = (state, action) => {
	return { ...state, data: { ...action.userData } };
};

const storeMedicalInsurances = (state, action) => {
	return { ...state, medicalInsurances: [...action.medicalInsurances] };
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state, action);
		case actionTypes.AUTH_UPDATE_TOKENS:
			return updateTokens(state, action);
		case actionTypes.AUTO_LOGIN_TRIED:
			return setAutoLoginTried(state, action);
		case actionTypes.STORE_USER_DATA:
			return storeUserData(state, action);
		case actionTypes.STORE_MEDICAL_INSURANCES:
			return storeMedicalInsurances(state, action);

		default:
			return state;
	}
};

export default reducer;
