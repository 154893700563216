export const validateEmail = (eMail) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(eMail).toLowerCase());
};

export function withThousends(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export const getAge = (birthday, birthmonth, birthyear) => {
	if (birthday.length > 0 && birthmonth.length > 0 && birthyear.length > 3) {
		const birthdate = new Date(birthyear, parseInt(birthmonth, 10), birthday);
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const age = today.getFullYear() - birthdate.getFullYear();
		const m = today.getMonth() - birthdate.getMonth();
		if ((m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) && age < 121) {
			return age - 1;
		}
		if (age > 120) {
			return -1;
		}
		return age;
	}
	return -1;
};

export const dateValid = (day, month, year, inPast = false) => {
	if (day.match(/^\d+$/) && month.match(/^\d+$/) && year.match(/^\d+$/)) {
		const jsMonth = parseInt(month, 10) - 1;
		const date = new Date(year, jsMonth, day);
		if (inPast && date.getTime() >= new Date().getTime()) {
			return false;
		}
		return date.getDate() === parseInt(day, 10) && date.getMonth() === jsMonth && date.getFullYear() === parseInt(year, 10);
	} else {
		return false;
	}
};

export const checkDate = (date) => {
	if (date.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
		const [year, month, day] = date.split("-");
		const theDate = new Date(year, month, day);
		return theDate.getDate() === parseInt(day, 10) && theDate.getMonth() === parseInt(month, 10) && theDate.getFullYear() === parseInt(year, 10);
	} else {
		return false;
	}
};

export const getMonthNames = (nr = null) => {
	const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
	if (nr !== null) {
		return monthNames[nr];
	} else {
		return monthNames;
	}
};

export const getDayNames = (nr = null) => {
	const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
	if (nr !== null) {
		return dayNames[nr];
	} else {
		return dayNames;
	}
};

export const formatDate = (date) => {
	const [year, month, day] = date.split("-");
	return parseInt(day, 10).toString() + "." + parseInt(month, 10).toString() + "." + year;
};

export const formatPhone = (phone) => {
	let formatedPhone = phone.replace(/ /g, "");
	if (formatedPhone.length > 3) {
		formatedPhone = formatedPhone.substr(0, 3) + " " + formatedPhone.substr(3);
		if (formatedPhone.length > 7) {
			formatedPhone = formatedPhone.substr(0, 7) + " " + formatedPhone.substr(7);
			if (formatedPhone.length > 10) {
				formatedPhone = formatedPhone.substr(0, 10) + " " + formatedPhone.substr(10);
			}
		}
	}
	return formatedPhone;
};
