import React from "react";

const Impressum = () => {
	return (
		<div className="indent">
			<h1>Impressum</h1>
			<br />
			<h3>AERZTEFON AG</h3>
			<div>
				<p>
					<strong>Redaktion & Softwareentwicklung</strong>
					<br />
					AERZTEFON AG
					<br />
					Binzmühlestrasse 95
					<br />
					CH-8050 Zürich
				</p>
				<p>
					<a href="mailto:sekretariat@aerztefon.ch">sekretariat@aerztefon.ch</a>
				</p>
			</div>
			<br />
			<br />
		</div>
	);
};

export default Impressum;
