import * as actionTypes from "./actionTypes";

import axios from "../../aerzteAxios";
import * as routes from "../../shared/routes";

import { startLoading, stopLoading, _setError, setRedirect, setTempSuccess, setTempError, storeFlat } from "./actions";

const errorOnSessionTimeout = "Sie wurden automatisch abgemeldet, da Sie seit einer Stunde inaktiv waren.";

export const _logout = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("refreshToken");
	return {
		type: actionTypes.AUTH_LOGOUT
	};
};

export const _login = (eMail, password) => {
	return (dispatch) => {
		dispatch(startLoading());
		const authData = {
			eMail: eMail,
			password: password
		};
		axios
			.post("login", authData)
			.then((response) => {
				dispatch(updateTokens(response.data));
				dispatch(storeUserData(response.data));
				dispatch(getFlat(response.data.userData.id, routes.ACCOUNT));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Die E-Mail Adresse oder das Passwort ist falsch!"));
				dispatch(stopLoading());
			});
	};
};

export const updateTokens = (serverData) => {
	localStorage.setItem("token", serverData.token);
	localStorage.setItem("refreshToken", serverData.refreshToken);
	return {
		type: actionTypes.AUTH_UPDATE_TOKENS,
		token: serverData.token,
		refreshToken: serverData.refreshToken,
		serverTime: serverData.serverTime
	};
};

export const tryAutoSignup = () => {
	return (dispatch) => {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refreshToken");
		if (!token) {
			dispatch(_logout());
		} else {
			axios
				.get("authenticated/autoSignup")
				.then((response) => {
					dispatch(storeUserData(response.data));
					dispatch(updateTokens({ token: token, refreshToken: refreshToken, serverTime: response.data.serverTime }));
					dispatch(_getUserData(response.data.userData.id));
					dispatch(getFlat(response.data.userData.id));
					dispatch(stopLoading());
				})
				.catch((err) => {
					if (isNotAuthorized(err)) {
						dispatch(_logout());
					}
					dispatch(stopLoading());
				});
			//}
		}
	};
};

export const storeUserData = ({ userData }) => {
	return {
		type: actionTypes.STORE_USER_DATA,
		userData: userData
	};
};

export const _getUserData = (id) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("authenticated/user/" + id)
			.then((response) => {
				dispatch(storeUserData(response.data));
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

export const getFlat = (id, redirect = null) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("authenticated/user/" + id + "/flat")
			.then((response) => {
				dispatch(storeFlat(response.data));
				if (redirect) {
					dispatch(setRedirect(redirect));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

const storeMedicalInsurances = (medicalInsurances) => {
	return {
		type: actionTypes.STORE_MEDICAL_INSURANCES,
		medicalInsurances: medicalInsurances
	};
};

export const _getMedicalInsurances = () => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("authenticated/medicalInsurances")
			.then((response) => {
				dispatch(storeMedicalInsurances(response.data));
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

const isNotAuthorized = (err) => {
	return err.response.status === 401;
};

export const _createPerson = (userId, person) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.post("authenticated/user/" + userId + "/person", person)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(_getUserData(userId));
				dispatch(setRedirect(routes.ACCOUNT));
				dispatch(setTempSuccess("«" + person.firstname + " " + person.lastname + "» wurde erfolgreich erstellt."));
			})
			.catch((err) => {
				dispatch(_setError("«" + person.firstname + " " + person.lastname + "» konnte nicht erstellt werden."));
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

export const _savePerson = (userId, personId, person) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.patch("authenticated/person/" + personId, person)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(_getUserData(userId));
				dispatch(setRedirect(routes.VIEW_PERSON + "/" + personId));
				dispatch(setTempSuccess("«" + person.firstname + " " + person.lastname + "» wurde erfolgreich gespeichert."));
			})
			.catch((err) => {
				dispatch(_setError("«" + person.firstname + " " + person.lastname + "» konnte nicht gespeichert werden."));
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

export const _deletePerson = (userId, personId, personName) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.delete("authenticated/user/" + userId + "/person/" + personId)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(_getUserData(userId));
				dispatch(setRedirect(routes.ACCOUNT));
				dispatch(setTempSuccess(personName + " wurde erfolgreich gelöscht."));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Die Person konnte nicht erstellt werden."));
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

export const _changeEmailAddress = (userId, eMail) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			eMail: eMail
		};
		axios
			.patch("authenticated/user/" + userId + "/changeEmailAddress", data)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(setRedirect(routes.ACCOUNT));
				dispatch(setTempSuccess("Wir haben Ihnen eine E-Mail an «" + eMail + "» gesendet. Bitte befolgen Sie die Instruktionen darin um Ihre neue E-Mail Adresse zu bestätigen."));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Die E-Mail Adresse konnte nicht geändert werden."));
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

export const _confirmEmailAddress = (uniqueKey) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			uniqueKey: uniqueKey
		};
		axios
			.patch("confirmEmailAddress", data)
			.then((response) => {
				dispatch(setTempSuccess("Ihre neue E-Mail Adresse wurde erfolgreich bestätigt."));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Die E-Mail Adresse konnte nicht bestätigt werden."));
				dispatch(stopLoading());
			});
	};
};

export const _changePassword = (userId, existingPassword, newPassword) => {
	return (dispatch) => {
		dispatch(startLoading());
		const data = {
			existingPassword: existingPassword,
			newPassword: newPassword
		};
		axios
			.patch("authenticated/user/" + userId + "/changePassword", data)
			.then((response) => {
				dispatch(setTempSuccess("Ihre neues Password wurde erfolgreich gespeichert."));
				dispatch(setRedirect(routes.ACCOUNT));
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (err.response.status === 406) {
					dispatch(_setError("Ihre aktuelles Password ist falsch."));
				} else {
					dispatch(_setError("Ihre neues Password konnte nicht gespeichert werden."));
				}
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};

export const _deleteAccount = (userId) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.delete("authenticated/user/" + userId)
			.then((response) => {
				dispatch(setTempSuccess("Ihre AERZTEFON-Konto wurde gelöscht."));
				dispatch(_logout());
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(_setError("Ihre AERZTEFON-Konto konnte nicht gelöscht werden."));
				if (isNotAuthorized(err)) {
					dispatch(_logout());
					dispatch(setTempError(errorOnSessionTimeout));
				}
				dispatch(stopLoading());
			});
	};
};
