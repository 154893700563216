import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPerson } from "../../../shared/objects";
import PersonForm from "../../../components/PersonForm/PersonForm";

import { _createPerson } from "../../../store/actions/userActions";

import Error from "../../../ui/Error";

const personObject = getPerson();

const AddPerson = () => {
	const [person, setPerson] = useState({ ...personObject });

	const userId = useSelector((state) => state.user.data.id);
	const dispatch = useDispatch();
	const createPerson = (userId, person) => dispatch(_createPerson(userId, person));

	const save = () => {
		const personData = { ...person };
		personData.phone = personData.phone.replace(/ /g, "");
		createPerson(userId, personData);
	};

	return (
		<div className="indent">
			<div className="panel">
				<h4>Person erfassen</h4>
				<Error />
				<PersonForm person={person} setPerson={setPerson} save={save} />
			</div>
		</div>
	);
};

export default AddPerson;
