import React from "react";
import { useHistory } from "react-router-dom";

import * as routes from "../../../shared/routes";

const Persons = ({ persons }) => {
	const history = useHistory();

	const listPersons = persons.map((person) => (
		<li key={person.id} onClick={() => history.push(routes.VIEW_PERSON + "/" + person.id)}>
			{person.firstname} {person.lastname}
		</li>
	));

	return (
		<div className="navPanel">
			<h4>Personen</h4>
			{listPersons.length > 0 ? <ul>{listPersons}</ul> : <i>Noch keine Personen erfasst</i>}
			<div className="buttonRow">
				<button type="button" className="right" onClick={() => history.push(routes.ADD_PERSON)}>
					Person erfassen
				</button>
			</div>
		</div>
	);
};

export default Persons;
