import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { _clearError } from "../store/actions/actions";

const Error = () => {
	const error = useSelector((state) => state.main.error);
	const dispatch = useDispatch();
	const clearError = useCallback(() => dispatch(_clearError()), [dispatch]);
	let result = "";
	if (error) {
		if (typeof error === "object") {
			result = error.map((entry, index) => <div key={index}>{entry}</div>);
		} else {
			result = error;
		}
	}

	useEffect(() => {
		return () => clearError();
	}, [clearError]);

	return result && <div className="error">{result}</div>;
};

export default Error;
