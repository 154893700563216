import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import { getGenders } from "../../shared/objects";

import { _getMedicalInsurances } from "../../store/actions/userActions";
import { _setError } from "../../store/actions/actions";
import { dateValid, formatPhone } from "../../shared/functions";
import * as routes from "../../shared/routes";

import SuggestionField from "../../shared/SuggestionField";

const PersonForm = ({ person, setPerson, save }) => {
	const dispatch = useDispatch();
	const getMedicalInsurances = useCallback(() => dispatch(_getMedicalInsurances()), [dispatch]);
	const medicalInsurances = useSelector((state) => state.user.medicalInsurances);
	const setError = (error) => dispatch(_setError(error));
	const history = useHistory();
	const genders = getGenders();

	useEffect(() => {
		if (!medicalInsurances.length) {
			getMedicalInsurances();
		}
	}, [getMedicalInsurances, medicalInsurances]);

	const savePerson = (event) => {
		event.preventDefault();
		const errors = [];

		if (!person.firstname.trim().length) {
			errors.push("Bitte geben Sie den Vornamen der Person an.");
		}
		if (!person.lastname.trim().length) {
			errors.push("Bitte geben Sie den Nachamen der Person an.");
		}
		if (!person.gender) {
			errors.push("Bitte geben Sie das Geschlecht der Person an.");
		}
		if ((person.birthDay || person.birthMonth || person.birthYear) && !dateValid(person.birthDay, person.birthMonth, person.birthYear, true)) {
			errors.push("Das angegebene Geburtsdatum ist ungültig.");
		}
		if (person.phone.length > 0 && person.phone.length < 13) {
			errors.push("Die angegebene Telefonnummer ist unvollständig.");
		}
		if (errors.length) {
			setError(errors);
		} else {
			save();
		}
	};

	const change = (event) => {
		const fieldname = event.target.name;
		const fieldvalue = event.target.value;

		switch (fieldname) {
			case "birthDay":
				if (!fieldvalue || (fieldvalue.match(/^\d*$/) && parseInt(fieldvalue, 10) < 32)) {
					setPerson((prev) => ({ ...prev, [fieldname]: fieldvalue }));
				}
				break;

			case "birthMonth":
				if (!fieldvalue || (fieldvalue.match(/^\d*$/) && parseInt(fieldvalue, 10) < 13)) {
					setPerson((prev) => ({ ...prev, [fieldname]: fieldvalue }));
				}
				break;

			case "birthYear":
			case "medicalInsuranceNr":
				if (fieldvalue.match(/^\d*$/)) {
					setPerson((prev) => ({ ...prev, [fieldname]: fieldvalue }));
				}
				break;

			case "phone":
				if (fieldvalue.match(/^[ |\d]*$/)) {
					const phone = formatPhone(fieldvalue);
					setPerson((prev) => ({ ...prev, [fieldname]: phone }));
				}
				break;

			default:
				setPerson((prev) => ({ ...prev, [fieldname]: fieldvalue }));
		}
	};

	const setMedicalInsurance = (value) => {
		setPerson((prev) => ({ ...prev, medicalInsurance: value }));
	};

	return (
		<form onSubmit={savePerson}>
			<label className="required">
				<span>Vorname*</span>
				<input type="text" name="firstname" value={person.firstname} onChange={change} maxLength={64} />
			</label>
			<label className="required">
				<span>Nachname*</span>
				<input type="text" name="lastname" value={person.lastname} onChange={change} maxLength={64} />
			</label>
			<label className="required noSpace">
				<span>Geschlecht*</span>
			</label>
			<div>
				{genders.map((gender) => (
					<label className="radio" key={gender.key}>
						<input type="radio" name="gender" value={gender.key} checked={person.gender === gender.key} onChange={change} />
						{gender.value}
					</label>
				))}
			</div>
			<label className="birthdate">
				<span>Geburtsdatum</span>
				<input type="text" className="number" name="birthDay" value={person.birthDay} onChange={change} maxLength={2} placeholder="Tag" />
				{" . "}
				<input type="text" className="number" name="birthMonth" value={person.birthMonth} onChange={change} maxLength={2} placeholder="Monat" />
				{" . "}
				<input type="text" className="number" name="birthYear" value={person.birthYear} onChange={change} maxLength={4} placeholder="Jahr" />
			</label>
			<label>
				<span>Telefon</span>
				<input type="text" name="phone" value={person.phone} onChange={change} maxLength={13} />
			</label>
			<label>
				<span>Hausarzt</span>
				<input type="text" name="familyDoctor" value={person.familyDoctor} onChange={change} maxLength={128} />
			</label>
			<label>
				<span>Krankenkasse</span>
				<SuggestionField maxLength={128} name="medicalInsurance" value={person.medicalInsurance} suggestions={medicalInsurances} update={setMedicalInsurance} />
			</label>
			<label>
				<span>Krankenkassen-Nr.</span>
				<input type="text" name="medicalInsuranceNr" value={person.medicalInsuranceNr} onChange={change} maxLength={20} />
			</label>
			<label>
				<span>Hilfreiche, medizinische Informationen</span>
				<TextareaAutosize name="medicalInformation" value={person.medicalInformation} onChange={change} minRows={3} />
			</label>
			<div className="buttonRow">
				<button type="submit" className="right">
					Speichern
				</button>
				<button type="button" className="right" onClick={() => history.push(person.id ? routes.VIEW_PERSON + "/" + person.id : routes.ACCOUNT)}>
					Abbrechen
				</button>
			</div>
		</form>
	);
};

export default PersonForm;
