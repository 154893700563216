import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { _getUserData, _savePerson } from "../../../store/actions/userActions";

import PersonForm from "../../../components/PersonForm/PersonForm";
import Error from "../../../ui/Error";
import { formatPhone } from "../../../shared/functions";

const EditPerson = () => {
	const params = useParams();
	const personId = params.personId ? params.personId : 0;
	const [person, setPerson] = useState(null);
	const userData = useSelector((state) => state.user.data);
	const editPerson = userData && userData.persons ? userData.persons.find((entry) => entry.id === personId) : null;
	const dispatch = useDispatch();
	const getUserData = useCallback((id) => dispatch(_getUserData(id)), [dispatch]);
	const savePerson = (userId, personId, person) => dispatch(_savePerson(userId, personId, person));

	useEffect(() => {
		if (userData && !userData.eMail) {
			getUserData(userData.id);
		}
	}, [getUserData, userData]);

	useEffect(() => {
		if (editPerson) {
			const birthdateFields = {};
			if (editPerson.birthdate) {
				[birthdateFields.birthYear, birthdateFields.birthMonth, birthdateFields.birthDay] = editPerson.birthdate.split("-");
			}
			editPerson.phone = formatPhone(editPerson.phone);
			setPerson({ ...editPerson, ...birthdateFields });
		}
	}, [editPerson]);

	const save = () => {
		const personData = { ...person };
		personData.phone = personData.phone.replace(/ /g, "");
		savePerson(userData.id, person.id, personData);
	};

	return (
		<div className="indent">
			<div className="panel">
				<h4>Person bearbeiten</h4>
				<Error />
				{person && <PersonForm person={person} setPerson={setPerson} save={save} />}
			</div>
		</div>
	);
};

export default EditPerson;
