import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { _deleteAccount } from "../../../store/actions/userActions";

import * as routes from "../../../shared/routes";
import Back from "../../../components/Back/Back";

const DeleteAccount = () => {
	const history = useHistory();
	const userId = useSelector((state) => state.user.data.id);
	const dispatch = useDispatch();
	const deleteAccount = (userId) => dispatch(_deleteAccount(userId));

	const deleteAccountNow = (event) => {
		event.preventDefault();
		deleteAccount(userId);
	};

	return (
		<div className="indent">
			<Back to={routes.ACCOUNT}>Ihr AERZTEFON-Konto</Back>
			<h1>AERZTEFON-Konto löschen</h1>
			<div className="panel">
				<h4>Möchten Sie wirklich unwiderruflich Ihr AERZTEFON-Konto löschen?</h4>
				<form onSubmit={deleteAccountNow}>
					<div className="buttonRow">
						<button type="submit">Ja, löschen</button>
						<button className="right" type="button" onClick={() => history.push(routes.ACCOUNT)}>
							Abbrechen
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DeleteAccount;
