import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AerztefonLogo from "../../ui/AerztefonLogo";
import Spinner from "../../ui/Spinner";
import Menu from "../../components/Menu/Menu";
import Footer from "../../ui/Footer";
import TempSuccess from "../../ui/TempSuccess";
import TempError from "../../ui/TempError";

import test from "../../assets/images/test.gif";
import development from "../../assets/images/development.gif";

const Layout = (props) => {
	const loading = useSelector((state) => state.main.loading);

	if (document.getElementById("topBar")) {
		if (process.env.REACT_APP_ENV === "test") {
			document.getElementById("topBar").style.backgroundImage = `url(${test})`;
		} else if (process.env.REACT_APP_ENV === "development") {
			document.getElementById("topBar").style.backgroundImage = `url(${development})`;
		}
	}

	return (
		<Fragment>
			<TempSuccess />
			<TempError />
			<div id="topBar">
				<div>
					<Link to="/">
						<AerztefonLogo />
					</Link>
					<Menu />
				</div>
			</div>
			<main>{props.children}</main>
			{loading && <Spinner />}
			<Footer />
		</Fragment>
	);
};

export default Layout;
