export function getGenders() {
	return [
		{ key: "w", value: "Weiblich" },
		{ key: "m", value: "Männlich" },
		{ key: "d", value: "Divers" }
	];
}

export function getPerson() {
	return {
		firstname: "",
		lastname: "",
		gender: "",
		birthDay: "",
		birthMonth: "",
		birthYear: "",
		phone: "",
		familyDoctor: "",
		medicalInsurance: "",
		medicalInsuranceNr: "",
		medicalInformation: ""
	};
}
