import React, { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./OrderStickers.module.css";
import { validateEmail } from "../../shared/functions";
import { useDispatch } from "react-redux";
import { _orderStickers } from "../../store/actions/actions";
import * as routes from "../../shared/routes";

const OrderStickers = () => {
	const dispatch = useDispatch();
	const orderStickers = (data) => dispatch(_orderStickers(data));
	const [order, setOrder] = useState({
		firstname: "",
		lastname: "",
		address: "",
		zip: "",
		city: "",
		eMail: ""
	});
	const [eMailValid, setEMailValid] = useState(true);
	const disabled = order.firstname.trim().length < 2 || order.lastname.trim().length < 2 || order.address.trim().length < 2 || order.zip.length !== 4 || order.city.trim().length < 2;

	const change = (event) => {
		const fieldname = event.target.name;
		const fieldvalue = event.target.value;

		setOrder((prev) => ({ ...prev, [fieldname]: fieldvalue }));
	};

	const enterMail = (event) => {
		const eMail = event.target.value;
		setOrder((prev) => ({ ...prev, eMail: eMail }));
		setEMailValid(eMail === "" || validateEmail(eMail));
	};

	const orderNow = (event) => {
		event.preventDefault();
		orderStickers(order);
	};

	return (
		<div className="indent">
			<h1>Nachbestellung persönliche QR-Codes</h1>
			<p>
				Die <Link to={routes.HOME}>Helpline AERZTEFON</Link> ist dank Ihrer Vermieterin kostenlos. Am schnellsten erreichen Sie die medizinischen Fachpersonen über Ihre persönlichen QR-Codes. Wir stellen Ihnen gerne ein neues oder zusätzliches Set davon zu.
			</p>
			<br />
			<form onSubmit={orderNow}>
				<label className="required">
					<span>Vorname*</span>
					<input type="text" name="firstname" value={order.firstname} onChange={change} maxLength={64} />
				</label>
				<label className="required">
					<span>Nachname*</span>
					<input type="text" name="lastname" value={order.lastname} onChange={change} maxLength={64} />
				</label>
				<label className="required">
					<span>Strasse*</span>
					<input type="text" name="address" value={order.address} onChange={change} maxLength={64} />
				</label>
				<div className={styles.zipCity}>
					<label className="required">
						<span>PLZ*</span>
						<input type="text" name="zip" value={order.zip} onChange={change} maxLength={4} />
					</label>
					<label className="required">
						<span>Ort*</span>
						<input type="text" name="city" value={order.city} onChange={change} maxLength={64} />
					</label>
				</div>
				<label>
					<span>E-Mail</span>
					<input type="text" name="eMail" value={order.eMail} onChange={enterMail} className={eMailValid ? "" : "error"} maxLength={128} />
				</label>
				<div className="buttonRow">
					<button type="submit" className="right" disabled={disabled}>
						Absenden
					</button>
				</div>
			</form>
			<br />
			<br />
		</div>
	);
};

export default OrderStickers;
