import { useEffect, useState } from "react";

const useGeolocation = () => {
	const [location, setLocation] = useState({
		loaded: false,
		error: null,
		location: {
			lat: "",
			lng: ""
		}
	});

	const onSuccess = (location) => {
		console.log(location);
		setLocation((prev) => ({ ...prev, loaded: true, location: { lat: location.coords.latitude, lng: location.coords.longitude } }));
	};

	const onError = (error) => {
		setLocation((prev) => ({ ...prev, loaded: false, error: error }));
	};

	useEffect(() => {
		if (!("geolocation" in navigator)) {
			onError({
				code: 0,
				error: "No geolocation service"
			});
		}
	}, []);

	const locate = () => {
		setLocation((prev) => ({ ...prev, loaded: false }));
		navigator.geolocation.getCurrentPosition(onSuccess, onError);
	};

	return [location, locate];
};

export default useGeolocation;
