import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as routes from "../../../shared/routes";
import { _checkCreateAccountKey } from "../../../store/actions/actions";
import Error from "../../../ui/Error";

const CreateAccount2 = () => {
	const params = useParams();
	const uniqueKey = params.uniqueKey ? params.uniqueKey : 0;
	const dispatch = useDispatch();
	const checkCreateAccountKey = useCallback((uniqueKey) => dispatch(_checkCreateAccountKey(uniqueKey)), [dispatch]);
	const registration = useSelector((state) => state.main.registration);
	const error = useSelector((state) => state.main.error);
	const history = useHistory();

	useEffect(() => {
		checkCreateAccountKey(uniqueKey);
	}, [uniqueKey, checkCreateAccountKey]);

	useEffect(() => {
		if (registration) {
			if (registration.state === "temp") {
				history.push(routes.CREATE_PASSWORD);
			} else {
				history.push(routes.LOGIN);
			}
		}
	}, [registration, history]);

	return (
		<div className="indent">
			{error ? (
				<Fragment>
					<h1>AERZTEFON-Konto erstellen</h1>
					<Error />
				</Fragment>
			) : (
				"Moment bitte..."
			)}
		</div>
	);
};

export default CreateAccount2;
