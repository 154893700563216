import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
	const version = useSelector((state) => state.main.version);

	return (
		<footer>
			<div>@ 2023 AERZTEFON AG | Version {version}</div>
		</footer>
	);
};

export default Footer;
