import React, { Suspense, useCallback, useEffect } from "react";
import { Route, Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as routes from "./shared/routes";

import { tryAutoSignup } from "./store/actions/userActions";
import { _clearRedirect } from "./store/actions/actions";

import Layout from "./hoc/Layout/Layout";

import Home from "./containers/Home/Home";
import Impressum from "./containers/Impressum/Impressum";
import OrderStickers from "./containers/OrderStickers/OrderStickers";
import AGB from "./containers/AGB/AGB";
import Flat from "./containers/Flat/Flat";
import PasswordForgotten from "./containers/PasswordForgotten/PasswordForgotten";
import Login from "./containers/Account/Login/Login";
import CreateAccount from "./containers/Account/CreateAccount/CreateAccount";
import CreateAccount2 from "./containers/Account/CreateAccount/CreateAccount2";
import CreatePassword from "./containers/Account/CreateAccount/CreatePassword";
import EditPerson from "./containers/Account/EditPerson/EditPerson";
import AddPerson from "./containers/Account/AddPerson/AddPerson";
import Account from "./containers/Account/Account";
import ChangePassword from "./containers/Account/ChangePassword/ChangePassword";
import ChangeEmailAddress from "./containers/Account/ChangeEmailAddress/ChangeEmailAddress";
import ConfirmEMailAddress from "./containers/ConfirmEMailAddress/ConfirmEMailAddress";
import Person from "./components/Account/Persons/Person/Person";
import DeleteAccount from "./containers/Account/DeleteAccount/DeleteAccount";
import NewPassword from "./containers/Account/NewPassword/NewPassword";

const App = () => {
	const authenticated = useSelector((state) => state.user.token !== null);
	const autoSignupTried = useSelector((state) => state.user.autoSignupTried);
	const redirect = useSelector((state) => state.main.redirect);
	const flat = useSelector((state) => state.main.flat);
	const dispatch = useDispatch();
	const onTryAutoSignup = () => dispatch(tryAutoSignup());
	const clearRedirect = useCallback(() => dispatch(_clearRedirect()), [dispatch]);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (redirect) {
			history.push(redirect);
		}
		return () => clearRedirect();
	}, [redirect, history, clearRedirect]);

	let route = null;

	if (!autoSignupTried) {
		onTryAutoSignup();
	} else {
		route = (
			<Switch location={location}>
				<Route path={routes.HOME} exact component={Home} />
				<Route path={routes.FLAT_KEY + "/:uniqueKey"} exact component={Flat} />
				<Route path={routes.PASSWORD_FORGOTTEN} exact component={PasswordForgotten} />
				{flat && <Route path={routes.FLAT} exact component={Flat} />}
				{!authenticated && <Route path={routes.LOGIN} exact component={Login} />}
				{!authenticated && <Route path={routes.CREATE_ACCOUNT} exact component={CreateAccount} />}
				{!authenticated && <Route path={routes.CREATE_ACCOUNT + "/:uniqueKey"} exact component={CreateAccount2} />}
				{!authenticated && <Route path={routes.CREATE_PASSWORD} exact component={CreatePassword} />}
				{authenticated && <Route path={routes.ACCOUNT} exact component={Account} />}
				{flat && authenticated && <Route path={routes.VIEW_PERSON + "/:personId"} exact component={Person} />}
				{flat && authenticated && <Route path={routes.VIEW_PERSON + "/:personId" + routes.EDIT_PERSON} exact component={EditPerson} />}
				{flat && authenticated && <Route path={routes.ADD_PERSON} exact component={AddPerson} />}
				{flat && authenticated && <Route path={routes.CHANGE_PASSWORD} exact component={ChangePassword} />}
				{flat && authenticated && <Route path={routes.CHANGE_EMAIL} exact component={ChangeEmailAddress} />}
				{flat && authenticated && <Route path={routes.DELETE_ACCOUNT} exact component={DeleteAccount} />}
				<Route path={routes.CONFIRM_EMAIL + "/:uniqueKey"} exact component={ConfirmEMailAddress} />
				<Route path={routes.NEW_PASSWORD + "/:uniqueKey"} exact component={NewPassword} />
				<Route path={routes.AGB} exact component={AGB} />
				<Route path={routes.IMPRESSUM} exact component={Impressum} />
				<Route path={routes.ORDER_STICKERS} exact component={OrderStickers} />
				<Redirect to={routes.HOME} />
			</Switch>
		);
	}

	return (
		<Layout>
			<Suspense fallback={<p>Laden...</p>}>{route}</Suspense>
		</Layout>
	);
};

export default App;
