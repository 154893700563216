import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { createStore, compose, applyMiddleware, combineReducers } from "redux";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import mainReducer from "./store/reducer/main";
import userReducer from "./store/reducer/user";

const rootReducer = combineReducers({
	main: mainReducer,
	user: userReducer
});

const composeEnhancers = (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const app = (
	<Provider store={store}>
		<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
