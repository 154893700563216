import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Draggable from "react-draggable";

import { ReactComponent as Phone } from "../../assets/images/phoneSlider.svg";
import { ReactComponent as Arrow } from "../../assets/images/arrowSlider.svg";
import { ReactComponent as Checkbox } from "../../assets/images/checkbox.svg";

import { _getFlat, _tenantCall } from "../../store/actions/actions";
import * as routes from "../../shared/routes";
import useGeolocation from "../../Hooks/useGeolocation";

const Flat = () => {
	const params = useParams();
	const uniqueKey = params.uniqueKey ? params.uniqueKey : 0;
	const dispatch = useDispatch();
	const getFlat = useCallback((uniqueKey) => dispatch(_getFlat(uniqueKey)), [dispatch]);
	const tenantCall = (uniqueKey, lat, lng) => dispatch(_tenantCall(uniqueKey, lat, lng));
	const flat = useSelector((state) => state.main.flat);
	const authenticated = useSelector((state) => state.user.token !== null);
	const [posX, setPosX] = useState(0);
	const [sendPos, setSendPos] = useState(false);
	const [lat, setLat] = useState(null);
	const [lng, setLng] = useState(null);
	const history = useHistory();
	const [geoLocation, locate] = useGeolocation();

	useEffect(() => {
		if (uniqueKey && !flat) {
			getFlat(uniqueKey);
		}
	}, [getFlat, uniqueKey, flat]);

	const handle = document.getElementsByClassName("handle")[0];

	const getSliderWidth = () => {
		const slider = document.getElementById("slider");
		const computedStyle = getComputedStyle(slider);
		return slider.clientWidth - parseInt(computedStyle.paddingLeft) - parseInt(computedStyle.paddingRight);
	};

	const resetSlider = () => {
		setPosX(0);
	};

	const handleDrag = (event, data) => {
		//const pos = Math.round((100 / (getSliderWidth() - handle.clientWidth)) * data.x);
		//console.log(pos + "%");
		// if (pos > 80) {
		// 	document.getElementById("slider").classList.add("green");
		// } else {
		// 	document.getElementById("slider").classList.remove("green");
		// }
		setPosX(data.x);
	};

	const handleStop = (event, data) => {
		const pos = Math.round((100 / (getSliderWidth() - handle.clientWidth)) * data.x);
		if (pos > 80) {
			setPosX(getSliderWidth() - handle.clientWidth);
			tenantCall(flat.uniqueKey, lat, lng);
			window.open("tel:+41442446040", "_self");
			window.setTimeout(resetSlider, 1000);
		} else {
			setPosX(0);
		}
		console.log(data);
		console.log(event);
	};

	const changeSendPos = (event) => {
		const send = event.target.checked;
		setSendPos(send);
		if (send) {
			locate();
		} else {
			setLat(null);
			setLng(null);
		}
	};

	useEffect(() => {
		if (geoLocation.loaded) {
			if (!geoLocation.error) {
				setLat(geoLocation.location.lat);
				setLng(geoLocation.location.lng);
			}
		}
	}, [geoLocation]);

	if (flat) {
		let changePersonalData = null;
		if (flat.userId === null) {
			changePersonalData = (
				<button type="button" className="right" onClick={() => history.push(routes.CREATE_ACCOUNT)}>
					Persönliche Daten erfassen
				</button>
			);
		} else if (authenticated) {
			changePersonalData = (
				<button type="button" className="right" onClick={() => history.push(routes.ACCOUNT)}>
					Persönliche Daten ändern
				</button>
			);
		} else {
			changePersonalData = (
				<button type="button" className="right" onClick={() => history.push(routes.LOGIN)}>
					Persönliche Daten ändern
				</button>
			);
		}

		return (
			<div className="indent">
				<h1>Medizinische Informationen anfordern</h1>
				<h3>Ihre Wohnung</h3>
				{flat.street}
				<br />
				{flat.zip} {flat.city}
				<br />
				<br />
				<label className="checkbox">
					<input type="checkbox" checked={sendPos} onChange={changeSendPos} />
					<Checkbox />
					Ich befinde mich nicht zuhause und möchte AERZTEFON meinen Standort senden, da dieser für meine Frage relevant ist.
				</label>
				{sendPos && (
					<div className="yourLocation">
						<strong>Ihr Standort:</strong> {lat ? lat + ", " + lng : geoLocation.error ? geoLocation.error : "Wird ermittelt...."}
					</div>
				)}
				<div id="slider" className={sendPos && !geoLocation.loaded ? "disabled" : ""}>
					<div className="callInfo">
						Jetzt anrufen <Arrow />
					</div>
					<Draggable disabled={sendPos && !geoLocation.loaded} axis="x" bounds="parent" defaultPosition={{ x: 0, y: 0 }} position={{ x: posX, y: 0 }} grid={[1, 25]} scale={1} onDrag={handleDrag} onStop={handleStop} defaultClassNameDragging="dragging">
						<div className="handle">
							<Phone />
						</div>
					</Draggable>
				</div>
				<br />
				<br />
				<br />
				<h3>Persönliche Daten</h3>
				<p>Damit wir Sie bei medizinischen Anliegen schneller beraten können haben Sie die Möglichkeit, Ihre persönlichen Daten zu hinterlegen.</p>
				<div className="buttonRow">{changePersonalData}</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Flat;
