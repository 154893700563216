import React from "react";

const AGB = () => {
	return (
		<div className="indent text">
			<h1>AGB & Datenschutz</h1>
			<h4>Leistungen</h4>
			<p>Das AERZTEFON ist eine Leistungserbringerin im Bereich medizinischer Notfälle mit Fokus auf nicht lebensbedrohliche Notfälle. In diesem Zusammenhang erbringt das AERZTEFON die folgenden Leistungen:</p>
			<ul>
				<li>Betrieb einer telefonischen Auskunftsstelle an 365 Tagen rund um die Uhr.</li>
				<li>Entgegennahme der Anrufe durch medizinisch geschultes Personal.</li>
				<li>Abklärung des Sachverhaltes.</li>
				<li>Bei unklaren Sachverhalten oder bei komplexen Fällen erfolgt die Rücksprache mit einer AERZTEFON-eigener Ärztin oder Arzt.</li>
				<li>Abschliessende medizinische Beratung oder Vermittlung an die geeignete medizinische Stelle (Hausarzt, Kinderarzt, Psychiater, Zahnarzt, Spital, etc.).</li>
				<li>Bei einem lebensbedrohlichen medizinischen Problem erfolgt das Aufbieten der Ambulanz oder die Verweisung an die Notfallstation eines Spitals.</li>
				<li>
					Vermittlung bedeutet:
					<ul>
						<li>Weiterleitung an einen Arzt oder Ärztin zur Durchführung einer Arztkonsultation per Telefon. Diese Ärzte unterstehen keinem Anstellungsverhältnis zum AERZTEFON und rechnen auf eigene Rechnung gemäss Tarmed ab.</li>
						<li>Weiterleitung an einen Arzt oder Ärztin zur Durchführung einer Arztkonsultation vor Ort in einer Praxis. Diese Ärzte unterstehen keinem Anstellungsverhältnis zum AERZTEFON und rechnen auf eigene Rechnung gemäss Tarmed ab.</li>
						<li>Weiterleitung an einen Arzt oder Ärztin zur Durchführung einer Arztkonsultation beim Anrufenden zu Hause (Hausbesuch durch einen Arzt oder Ärztin). Diese Ärzte unterstehen keinem Anstellungsverhältnis zum AERZTEFON und rechnen auf eigene Rechnung gemäss Tarmed ab.</li>
					</ul>
				</li>
			</ul>
			<h4>Kosten</h4>
			<p>Der Anruf auf die telefonische Auskunftsstelle des AERZTEFON erfolgt zum Lokaltarif.</p>
			<p>
				Die Entgegennahme des Anrufes, die Abklärung des Sachverhaltes, die Rücksprache mit dem AERZTEFON-eigenen Arzt / Ärztin sowie die abschliessende medizinische Beratung durch die Mitarbeitenden des AERZTEFONs und/oder die Vermittlungsdienstleistung an die geeignete medizinische Stelle
				durch die Mitarbeitenden des AERZTEFONs sind für den / die Anrufende(n) kostenlos.
			</p>
			<p>
				Die Behandlung (telefonische Arztkonsultation, Arztbesuch in der Praxis, Ambulanz, Spital) ist kostenpflichtig. Die Rechnungstellung erfolgt direkt durch den sich nicht im Anstellungsverhältnis mit dem AERZTEFON befindenden Arzt / Ärztin oder durch die entsprechende Institution
				(Ambulanz, Spital).
			</p>
			<h4>Datenschutz</h4>
			<p>Es wird hiermit ausdrücklich darauf hingewiesen, dass das AERZTEFON erlangte Daten entsprechend den gesetzlichen datenschutzrechtlichen Bestimmungen erhebt, speichert und verarbeitet.</p>
			<p>Das AERZTEFON verfügt über eine Institutionsbewilligung der Gesundheitsdirektion des Kantons Zürich.</p>
			<p>Sämtliche Mitarbeitende des AERZTEFON unterstehen der ärztlichen Schweigepflicht.</p>
			<p>Sämtliche Gespräche werden aus beweisrechtlichen Gründen und zu Schulungszwecken aufgezeichnet. Um die Tonbandansage kurz zu halten, wird der Anrufende auf diese Tatsache nicht aufmerksam gemacht.</p>
			<p>Die Mitarbeitenden des AERZTEFONs dokumentieren im Rahmen der Patientenhistorie die eingehenden Anrufe schriftlich.</p>
			<p>Sämtliche gespeicherten Daten (Gesprächsaufzeichnung, Patientenhistorie, die selber in der Web-Applikation hinterlegten Daten, etc.) werden auf einem Server mit Standort in der Schweiz gespeichert. </p>
			<p>Sämtliche Daten werden mindestens 10 Jahre lang zur Dritte unzugänglich aufbewahrt.</p>
			<p>
				Das AERZTEFON ist verpflichtet, im Rahmen einer allfälligen strafrechtlichen Untersuchung gegen das AERZTEFON oder deren Mitarbeitende, den entsprechenden Behörden (Polizei, Staatsanwaltschaft) auf Ersuchen hin, Daten auszuhändigen. In einem solchen Fall wird die betroffene Person
				direkt und unverzüglich von der Geschäftsleitung des AERZTEFONs informiert.
			</p>
			<h4>Haftung</h4>
			<p>Das AERZTEFON übernimmt in den folgenden Fällen keine Haftung:</p>
			<ul>
				<li>Wenn durch Störung oder Ausfall des Telefonnetzes die Dienstleistungserbringung behindert, bzw. verhindert wird. In einem solchen Fall obliegt es dem / der Anrufenden, im Zweifelsfalle die Ambulanz aufzubieten oder sich in eine Notfallstation eines Spitals zu begeben.</li>
				<li>Wenn beispielsweise Informationen seitens Anrufende(r) inhaltlich nicht, ungenügend oder falsch verstanden werden.</li>
				<li>Wenn durch das AERZTEFON, bzw. durch deren Mitarbeitende gemachte Anweisungen, Empfehlungen, etc. nicht oder nur teilweise befolgt werden.</li>
			</ul>
			<p>Das AERZTEFON haftet weiter nicht für die Empfehlungen, Anweisungen und Behandlungen durch sich nicht im Anstellungsverhältnis mit dem AERZTEFON befindende Ärztinnen, Ärzte und Institutionen wie bspw. Ambulanz und Spitäler.</p>
			<h4>Schlussbestimmungen, Gerichtsstand</h4>
			<p>
				Das AERZTEFON ist berechtigt, die allgemeinen Geschäftsbedingungen nachträglich zu ändern oder zu ergänzen. In diesem Fall setzt sie ihren Auftraggeber hiervon in Kenntnis und weist ihn darauf hin, dass er berechtigt ist, innerhalb einer Frist von zwei Wochen nach Zugang der Änderung
				zu widersprechen. Widerspricht der Auftraggeber innerhalb der genannten Frist der Änderung nicht, werden die geänderten Bedingungen Vertragsbestandteil. Anderenfalls verbleibt es bei den ursprünglich vereinbarten allgemeinen Geschäftsbedingungen.
			</p>
			<p>
				Sollte eine der Klauseln dieser Allgemeinen Geschäftsbedingungen unwirksam sein, berührt dies nicht die Wirksamkeit der übrigen Klauseln. Die unwirksame Klausel soll sodann durch eine Klausel ersetzt werden, welche den Interessen der Parteien und der von ihnen beabsichtigten Regelung
				möglichst nahekommt.
			</p>
			<p>Der Gerichtstand ist Zürich.</p>
			<br />
			<br />
			<p>Zürich, 9. Februar 2022</p>
		</div>
	);
};

export default AGB;
