import React from "react";
import { NavLink } from "react-router-dom";

const MenuPoint = ({ name, url, hideMenu }) => {
	return (
		<li>
			<NavLink exact onClick={hideMenu} to={url}>
				{name}
			</NavLink>
		</li>
	);
};

export default MenuPoint;
