export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const SET_TEMP_SUCCESS = "SET_TEMP_SUCCESS";
export const SET_TEMP_ERROR = "SET_TEMP_ERROR";
export const CLEAR_TEMP_SUCCESS = "CLEAR_TEMP_SUCCESS";
export const CLEAR_TEMP_ERROR = "CLEAR_TEMP_ERROR";
export const AUTH_UPDATE_TOKENS = "AUTH_UPDATE_TOKENS";
export const AUTH_SESSION_TIMEOUT = "AUTH_SESSION_TIMEOUT";
export const SET_REDIRECT = "SET_REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
export const AUTO_LOGIN_TRIED = "AUTO_LOGIN_TRIED";

export const STORE_FLAT = "STORE_FLAT";

export const STORE_REGISTRATION = "STORE_REGISTRATION";
export const CLEAR_REGISTRATION = "CLEAR_REGISTRATION";
export const STORE_USER_DATA = "STORE_USER_DATA";
export const STORE_MEDICAL_INSURANCES = "STORE_MEDICAL_INSURANCES";
