import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as routes from "../../shared/routes";

import { _confirmEmailAddress } from "../../store/actions/userActions";
import Error from "../../ui/Error";

const ConfirmEMailAddress = () => {
	const params = useParams();
	const uniqueKey = params.uniqueKey ? params.uniqueKey : 0;
	const dispatch = useDispatch();
	const confirmEmailAddress = useCallback((uniqueKey) => dispatch(_confirmEmailAddress(uniqueKey)), [dispatch]);
	const authenticated = useSelector((state) => state.user.token !== null);
	const success = useSelector((state) => state.main.tempSuccess);
	const history = useHistory();

	useEffect(() => {
		confirmEmailAddress(uniqueKey);
	}, [uniqueKey, confirmEmailAddress]);

	useEffect(() => {
		if (success) {
			if (authenticated) {
				history.push(routes.ACCOUNT);
			} else {
				history.push(routes.LOGIN);
			}
		}
	}, [success, authenticated, history]);

	return (
		<div>
			<h1>E-Mail Adresse bestätigen</h1>
			<Error />
		</div>
	);
};

export default ConfirmEMailAddress;
