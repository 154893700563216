import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import StartImage from "../../assets/images/startimage.jpg";
import UnserService from "../../assets/images/unserService.jpg";
import SoErreichenSieUns from "../../assets/images/soErreichenSieUns.jpg";
import DatenHinterlegen from "../../assets/images/datenHinterlegen.jpg";
import Schnell from "../../assets/images/schnell.jpg";
import { clearSuccess } from "../../store/actions/actions";

const Home = () => {
	const success = useSelector((state) => state.main.success);
	const dispatch = useDispatch();
	const clearSuccessNow = useCallback(() => dispatch(clearSuccess()), [dispatch]);

	useEffect(() => {
		return () => clearSuccessNow();
	}, [clearSuccessNow]);

	return (
		<div id="home">
			{success && <div className="success">{success}</div>}
			<img src={StartImage} alt="AERZTEFON AG" />
			<div className="indent">
				<h1 className="red">GENIESSEN SIE DAS LEBEN</h1>
				<h1>IM NOTFALL SIND WIR FÜR SIE DA</h1>
				<ul>
					<li>
						Ihre <strong>Helpline</strong> für <strong>medizinische Fragen und Probleme</strong>, die nicht lebensbedrohlich sind
					</li>
					<li>
						<strong>365</strong> Tage, <strong>24</strong> Stunden erreichbar
					</li>
					<li>
						<strong>Kostenlos</strong> und <strong>persönlich</strong> auf Sie abgestimmt – dank Ihrer Vermieterin
					</li>
				</ul>
				<h2>UNSER SERVICE</h2>
				<img src={UnserService} alt="Unser Service" />
				<p>Das AERZTEFON berät und unterstützt Sie in jeder medizinischen Frage. Unsere Fachpersonen klären das Problem schnell ab und vermitteln Ihnen den passenden Kontakt in Ihrer Nähe. Falls akute Lebensgefahr besteht, wählen Sie sofort die Notrufnummer 144!</p>
				<h2>SO ERREICHEN SIE UNS</h2>
				<img src={SoErreichenSieUns} alt="So erreichen Sie uns" />
				<div className="twoCols">
					<div>
						<strong>QR-Code</strong> für Ihre
						<br />
						Wohnung <strong>scannen</strong>
					</div>
					<div>
						Menüauswahl:
						<br />
						<strong>«Aerztefon anrufen»</strong>
					</div>
				</div>
				<p>
					Dank der QR-Code-Funktion werden Sie <strong>direkt</strong> und ohne Eingabe einer Telefonnummer mit unserem medizinisch geschulten Berater-Team <strong>verbunden</strong>.
				</p>
				<h2>DATEN HINTERLEGEN</h2>
				<img src={DatenHinterlegen} alt="Daten hinterlegen" />
				<div className="twoCols">
					<div>
						<strong>QR-Code</strong> für Ihre
						<br />
						Wohnung <strong>scannen</strong>
					</div>
					<div>
						Menüauswahl:
						<br />
						<strong>«Persönliche Daten»</strong>
					</div>
				</div>
				<p>
					Sie können jederzeit <strong>persönliche Angaben</strong> sicher erfassen. Jede Zusatzinformation ist <strong>im Notfall</strong> und bei grosser Nervosität <strong>sehr hilfreich</strong>.
				</p>
				<h2>UNSERE ARGUMENTE</h2>
				<h2 className="black">VERTRAUENSWÜRDIG</h2>
				<p>Ihre Daten sind beim AERZTEFON streng geschützt und unterstehen dem Arztgeheimnis. Sie können nur von unseren medizinischen Fachpersonen eingesehen werden.</p>
				<h2 className="black">KOMPETENT</h2>
				<p>Unsere Fachpersonen sind speziell für die telefonische Beratung und Vermittlung in medizinischen Notfällen geschult. Das AERZTEFON steht seit über 50 Jahren im Dienst der Bevölkerung. Wichtigster Auftraggeber ist der Kanton Zürich.</p>
				<h2 className="black">KOSTENLOS</h2>
				<p>Dank Ihrer Vermieterin sind Ihr Anruf und die Beratung beim AERZTEFON kostenlos. Bei einer Weitervermittlung wird Ihr Fall durch den medizinischen Dienstleister (Notfallarzt, Apotheker, Psychiater etc.) nach gültigen Tarifen abgerechnet.</p>
				<h2 className="black">SCHNELL</h2>
				<p>Das AERZTEFON ist am direktesten über die QR-Code-Funktion erreichbar.</p>
				<img src={Schnell} alt="Schnell" />
				<p>
					Bringen Sie die Kleber mit Ihrem persönlichen QR-Code dort an, wo Sie am ehesten Hilfe benötigen. <strong>Tipp:</strong> Nicht zu hoch aufkleben.
				</p>
			</div>
		</div>
	);
};

export default Home;
