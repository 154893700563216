import React, { useEffect, useRef, useState } from "react";
import ReactPasswordStrength from "react-password-strength";
import { useSelector, useDispatch } from "react-redux";

import { _setError, _clearError, _createPassword } from "../../../store/actions/actions";
import Error from "../../../ui/Error";

const CreatePassword = () => {
	const [password, setPassword] = useState("");
	const [passwordValid, setPasswordValid] = useState(false);
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const ref = useRef();
	const dispatch = useDispatch();
	const setError = (error) => dispatch(_setError(error));
	const clearError = () => dispatch(_clearError());
	const createPassword = (userId, password) => dispatch(_createPassword(userId, password));
	const registration = useSelector((state) => state.main.registration);

	useEffect(() => {
		ref.current.reactPasswordStrengthInput.focus();
	}, []);

	const changePassword = (state) => {
		setPassword(state.password);
		setPasswordValid(state.isValid);
	};

	const savePassword = (event) => {
		event.preventDefault();
		clearError();
		if (password !== passwordConfirm) {
			setError("Das Passwort und dessen Bestätigung sind nicht identisch.");
		} else {
			createPassword(registration.id, password);
		}
	};

	return (
		<div className="indent">
			<h1>Password definieren</h1>
			<div className="panel">
				<h4>Bitte definieren Sie ein Passwort für Ihr neues AERZTEFON-Konto:</h4>
				<Error />
				<form onSubmit={savePassword}>
					<label>
						<span>Passwort</span>
						<ReactPasswordStrength ref={ref} minLength={8} minScore={2} scoreWords={["Sehr unsicher", "Unsicher", "Gut", "Sicher", "Sehr sicher"]} tooShortWord={"Zu kurz"} changeCallback={changePassword} />
					</label>
					<label>
						<span>Passwort (Bestätigung)</span>
						<input type="password" value={passwordConfirm} onChange={(event) => setPasswordConfirm(event.target.value)} />
					</label>
					<div className="buttonRow">
						<button className="right" type="submit" disabled={!passwordValid}>
							Speichern
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CreatePassword;
