import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { _changeEmailAddress } from "../../../store/actions/userActions";
import { _setError, _clearError } from "../../../store/actions/actions";
import * as routes from "../../../shared/routes";
import { validateEmail } from "../../../shared/functions";
import Back from "../../../components/Back/Back";
import Error from "../../../ui/Error";

const ChangeEmailAddress = () => {
	const existing_eMail = useSelector((state) => state.user.data.eMail);
	const userId = useSelector((state) => state.user.data.id);
	const history = useHistory();
	const [eMail, setEMail] = useState("");
	const [eMailValid, setEMailValid] = useState(false);
	const ref = useRef();
	const dispatch = useDispatch();
	const changeEmailAddress = (userId, eMail) => dispatch(_changeEmailAddress(userId, eMail));
	const setError = (error) => dispatch(_setError(error));
	const clearError = useCallback(() => dispatch(_clearError()), [dispatch]);

	useEffect(() => {
		ref.current.focus();
	}, []);

	useEffect(() => {
		return () => clearError();
	}, [clearError]);

	const save = (event) => {
		event.preventDefault();
		if (eMail === existing_eMail) {
			setError("Die neue und die alte E-Mail Adresse sind identisch.");
		} else {
			changeEmailAddress(userId, eMail);
		}
	};

	const enterMail = (event) => {
		const eMail = event.target.value;
		clearError();
		setEMail(eMail);
		setEMailValid(validateEmail(eMail));
	};

	return (
		<div className="indent">
			<Back to={routes.ACCOUNT}>Ihr AERZTEFON-Konto</Back>
			<h1>E-Mail Adresse ändern</h1>
			<div className="panel">
				<h4>Wenn Sie Ihre E-Mail Adresse ändern werden wir Ihnen eine E-Mail auf Ihre neue E-Mail Adresse senden, mit welcher Sie die Änderung bestätigen müssen.</h4>
				<Error />
				<form onSubmit={save}>
					<label>
						<span>Ihre neue E-Mail Adresse (anstelle von {existing_eMail})</span>
						<input ref={ref} type="text" className={eMail.length && !eMailValid ? "error" : ""} autoComplete="username" value={eMail} maxLength={128} onChange={enterMail} />
					</label>
					<div className="buttonRow">
						<button type="submit" className="right" disabled={!eMailValid}>
							Speichern
						</button>
						<button type="button" className="right" onClick={() => history.push(routes.ACCOUNT)}>
							Abbrechen
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ChangeEmailAddress;
