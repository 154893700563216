import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import * as routes from "../../shared/routes";
import MenuPoint from "./MenuPoint/MenuPoint";

import { ReactComponent as Phone } from "../../assets/images/phone.svg";
import { ReactComponent as Profil } from "../../assets/images/profil.svg";

const menuPoints = [
	{
		url: routes.HOME,
		name: "Startseite"
	},
	{
		url: routes.AGB,
		name: "AGB & Datenschutz"
	},
	{
		url: routes.IMPRESSUM,
		name: "Impressum"
	}
];

const Menu = () => {
	const [showMenu, setShowMenu] = useState(false);
	const flat = useSelector((state) => state.main.flat);
	const authenticated = useSelector((state) => state.user.token !== null);
	const menu = menuPoints.map(({ url, name }) => <MenuPoint key={url} url={url} name={name} hideMenu={() => setShowMenu(false)} />);
	const history = useHistory();
	const location = useLocation();

	const goToFlat = () => {
		setShowMenu(false);
		history.push(routes.FLAT);
	};

	const goToAccount = () => {
		setShowMenu(false);
		if (authenticated) {
			history.push(routes.ACCOUNT);
		} else {
			history.push(routes.LOGIN);
		}
	};

	const inFlat = location.pathname.indexOf(routes.FLAT) === 0;
	const inAccount = location.pathname.indexOf(routes.ACCOUNT) === 0;

	return (
		<div id="menu">
			{flat && (
				<div className={"icon" + (inFlat ? " selected" : "")} onClick={goToFlat}>
					<Phone />
				</div>
			)}
			<div className={"icon" + (inAccount ? " selected" : "")} onClick={goToAccount}>
				<Profil />
			</div>
			<div className={"icon strokes" + (showMenu ? " open" : !(inFlat || inAccount) ? " selected" : "")} onClick={() => setShowMenu((prev) => !prev)}>
				<div></div>
				<div></div>
				<div></div>
			</div>
			{showMenu && (
				<Fragment>
					<div className="menuCover" onClick={() => setShowMenu((prev) => !prev)}></div>
					<div className="menu">
						<div>
							<ul>{menu}</ul>
							<address>
								AERZTEFON AG
								<br />
								Binzmühlestrasse 95
								<br />
								CH-8050 Zürich
								<br />
								<br />
								<a href="mailto:sekretariat@aerztefon.ch">sekretariat@aerztefon.ch</a>
							</address>
						</div>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default Menu;
