import React, { useState } from "react";

const SuggestionField = ({ name, value, suggestions, update, maxLength, placeholder }) => {
	const [showSuggestion, setShowSuggestion] = useState(false);
	const [selected, setSelected] = useState(-1);

	const overtakeSuggestion = (entry) => {
		update(entry);
		setShowSuggestion(false);
		setSelected(-1);
	};

	const onChange = (event) => {
		const fieldValue = event.target.value;
		setShowSuggestion(true);
		setSelected(-1);
		update(fieldValue);
	};

	const checkKey = (event) => {
		if (event.key === "ArrowDown") {
			if (filteredSuggestions.length > selected + 1) {
				setSelected((prev) => prev + 1);
				event.stopPropagation();
				event.preventDefault();
			}
		} else if (event.key === "ArrowUp") {
			if (selected > 0) {
				setSelected((prev) => prev - 1);
				event.stopPropagation();
				event.preventDefault();
			}
		} else if (event.key === "Enter") {
			event.stopPropagation();
			event.preventDefault();
			overtakeSuggestion(filteredSuggestions[selected]);
		} else {
			console.log(event.key);
		}
	};

	const lostFocus = () => {
		window.setTimeout(() => setShowSuggestion(false), 200);
	};

	const filteredSuggestions = suggestions.filter((entry) => value.length >= 2 && entry.toLowerCase().indexOf(value.toLowerCase()) > -1);
	const filteredSuggestionsMenu = filteredSuggestions.map((entry, index) => (
		<li key={index} className={selected === index ? "selected" : null} onClick={() => overtakeSuggestion(entry)}>
			{entry}
		</li>
	));

	return (
		<div className="suggestionField">
			<input type="text" name={name} value={value} autoComplete="off" onChange={onChange} onKeyDown={checkKey} maxLength={maxLength} onBlur={lostFocus} placeholder={placeholder} />
			{showSuggestion && filteredSuggestions.length > 0 && <ul>{filteredSuggestionsMenu}</ul>}
		</div>
	);
};

export default SuggestionField;
