import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { _login } from "../../../store/actions/userActions";
import { _setError } from "../../../store/actions/actions";
import * as routes from "../../../shared/routes";

import Error from "../../../ui/Error";

const Login = () => {
	const [eMail, setEMail] = useState("");
	const [password, setPassword] = useState("");
	const dispatch = useDispatch();
	const history = useHistory();

	const sendLogin = (eMail, password) => dispatch(_login(eMail, password));
	const setError = (error) => dispatch(_setError(error));

	const changeEMail = (event) => {
		setEMail(event.target.value);
	};

	const changePasswort = (event) => {
		setPassword(event.target.value);
	};

	const login = (event) => {
		event.preventDefault();
		const errors = [];
		if (!eMail.length) {
			errors.push("Bitte geben Sie Ihre E-Mail Adresse an.");
		}
		if (!password.length) {
			errors.push("Bitte geben Sie Ihr Passwort an.");
		}
		if (errors.length) {
			setError(errors);
		} else {
			setError(null);
			sendLogin(eMail, password);
		}
	};

	return (
		<div className="indent">
			<h1>Anmelden</h1>
			<div className="panel">
				<h4>Wenn Sie bereits ein AERZTEFON-Konto haben, können Sie sich hier anmelden.</h4>
				<Error />
				<form onSubmit={login}>
					<label>
						<span>E-Mail</span>
						<input type="text" autoComplete="username" value={eMail} onChange={changeEMail} />
					</label>
					<label>
						<span>Passwort</span>
						<input type="password" autoComplete="current-password" value={password} onChange={changePasswort} />
					</label>
					<div className="buttonRow">
						<button className="right" type="submit">
							Anmelden
						</button>
					</div>
				</form>
			</div>
			<div className="navPanel">
				<h4>Weitere Funktionen</h4>
				<ul>
					<li onClick={() => history.push(routes.CREATE_ACCOUNT)}>Wenn Sie noch kein AERZTEFON-Konto besitzen, können Sie hier eins erstellen</li>
					<li onClick={() => history.push(routes.PASSWORD_FORGOTTEN)}>Ich habe mein Passwort vergessen</li>
				</ul>
			</div>
		</div>
	);
};

export default Login;
