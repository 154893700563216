export const HOME = "/";
export const AGB = "/agb";
export const IMPRESSUM = "/impressum";
export const FLAT = "/wohnung";
export const FLAT_KEY = FLAT + "/key";
export const ACCOUNT = "/aerztefon_konto";
export const LOGIN = ACCOUNT + "/anmelden";
export const PASSWORD_FORGOTTEN = ACCOUNT + "/password_vergessen";
export const VIEW_PERSON = ACCOUNT + "/person";
export const ADD_PERSON = ACCOUNT + "/person_erfassen";
export const EDIT_PERSON = "/bearbeiten";
export const CHANGE_PASSWORD = ACCOUNT + "/passwort_aendern";
export const CHANGE_EMAIL = ACCOUNT + "/email_adresse_aendern";
export const CONFIRM_EMAIL = "/eMail_bestaetigen";
export const DELETE_ACCOUNT = ACCOUNT + "/loeschen";
export const CREATE_ACCOUNT = "/konto_erstellen";
export const CREATE_PASSWORD = "/password_definieren";
export const NEW_PASSWORD = "/neues_passwort";
export const ORDER_STICKERS = "/nachbestellung";
