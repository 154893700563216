import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import nl2br from "react-nl2br";

import { getGenders } from "../../../../shared/objects";
import * as routes from "../../../../shared/routes";
import { _deletePerson } from "../../../../store/actions/userActions";
import { formatDate, formatPhone } from "../../../../shared/functions";
import Back from "../../../Back/Back";

function Person() {
	const params = useParams();
	const personId = params.personId ? params.personId : 0;
	const person = useSelector((state) => state.user.data.persons.find((person) => person.id === personId));
	const userId = useSelector((state) => state.user.data.id);
	const [askDelete, setAskDelete] = useState(false);
	const dispatch = useDispatch();
	const deletePerson = (userId, personId, personName) => dispatch(_deletePerson(userId, personId, personName));
	const history = useHistory();
	const genders = getGenders();

	const showAskDelete = () => {
		setAskDelete(true);
	};

	const cancelAskDelete = () => {
		setAskDelete(false);
	};

	const deletePersonNow = () => {
		deletePerson(userId, person.id, person.firstname + " " + person.lastname);
	};

	const editPerson = () => {
		history.push(routes.VIEW_PERSON + "/" + person.id + routes.EDIT_PERSON);
	};

	return (
		<div className="indent">
			<Back to={routes.ACCOUNT}>Ihr AERZTEFON-Konto</Back>
			<div className="panel">
				{askDelete && (
					<div className="error">
						Möchten Sie {person.firstname} {person.lastname} wirklich löschen?
					</div>
				)}
				<h4>
					{person.firstname} {person.lastname}
				</h4>
				<div className="view">
					<label>
						<span>Geschlecht</span>
						{person.gender ? genders.find((gender) => gender.key === person.gender).value : "-"}
					</label>
					<label>
						<span>Geburtsdatum</span>
						{person.birthdate ? formatDate(person.birthdate) : "-"}
					</label>
					<label>
						<span>Telefon</span>
						{person.phone ? formatPhone(person.phone) : "-"}
					</label>
					<label>
						<span>Hausarzt</span>
						{person.familyDoctor ? person.familyDoctor : "-"}
					</label>
					<label>
						<span>Krankenkasse</span>
						{person.medicalInsurance ? person.medicalInsurance : "-"}
					</label>
					<label>
						<span>Krankenkassen-Nr.</span>
						{person.medicalInsuranceNr ? person.medicalInsuranceNr : "-"}
					</label>
					<label>
						<span>Hilfreiche, medizinische Informationen</span>
						<div>{person.medicalInformation ? nl2br(person.medicalInformation) : "-"}</div>
					</label>
					<div className="buttonRow">
						{askDelete ? (
							<Fragment>
								<button type="button" className="right" onClick={cancelAskDelete}>
									Abbrechen
								</button>
								<button type="button" className="right" onClick={deletePersonNow}>
									Ja, löschen
								</button>
							</Fragment>
						) : (
							<Fragment>
								<button type="button" onClick={showAskDelete}>
									Löschen
								</button>
								<button type="button" className="right" onClick={editPerson}>
									Bearbeiten
								</button>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Person;
