import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as routes from "../../shared/routes";

import { _getUserData, _logout } from "../../store/actions/userActions";
import Persons from "../../components/Account/Persons/Persons";
import { useHistory } from "react-router-dom";

const Account = () => {
	const userData = useSelector((state) => state.user.data);
	const dispatch = useDispatch();
	const getUserData = useCallback((id) => dispatch(_getUserData(id)), [dispatch]);
	const logout = () => dispatch(_logout());
	const loaded = userData && userData.eMail;
	const history = useHistory();

	useEffect(() => {
		if (userData && !userData.eMail) {
			getUserData(userData.id);
		}
	}, [getUserData, userData]);

	return (
		<div className="indent">
			<h1>Ihr AERZTEFON-Konto</h1>
			{loaded ? <Fragment>{userData.persons && <Persons persons={userData.persons} userId={userData.id} />}</Fragment> : "Ihre Daten werden geladen..."}
			<div className="navPanel">
				<h4>Weitere Funktionen</h4>
				<ul>
					<li onClick={() => history.push(routes.CHANGE_PASSWORD)}>Passwort ändern</li>
					<li onClick={() => history.push(routes.CHANGE_EMAIL)}>E-Mail Adresse ändern</li>
					<li onClick={() => history.push(routes.DELETE_ACCOUNT)}>AERZTEFON-Konto löschen</li>
				</ul>
			</div>
			<div className="buttonRow">
				<button type="button" className="right" onClick={logout}>
					Abmelden
				</button>
			</div>
		</div>
	);
};

export default Account;
